import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      disposalLicence: {
        companyInfo: {},
        licenceInfo: {
          municipalityInfo: {},
          targetJwnetItemList: [],
          disposalWayList: [],
        },
      },
      commonWasteDisposalLicence: {
        companyInfo: {},
        licenceInfo: {
          municipalityInfo: {},
          targetJwnetItemList: [],
          disposalWayList: [],
        },
      },
    };
  },
  methods: {
    //処分許可証情報取得API
    getLicenceDetailApi(id) {
      return axios
        .get(API_URL.LICENCE.DISPOSAL_LICENCE + id)
        .then((res) => {
          this.disposalLicence = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //一般廃棄物処分許可証情報取得API
    getCommonWasteLicenceDetailApi(id) {
      return axios
        .get(API_URL.LICENCE.COMMON_DISPOSAL_LICENCE + id)
        .then((res) => {
          this.commonWasteDisposalLicence = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
