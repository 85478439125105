<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="処分許可証詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">許可証情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>企業名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposalLicence.companyInfo.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposalLicence.licenceInfo.licenceNo }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証期限</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposalLicence.licenceInfo.licenceExpiredDate | dateJpYMDFormat }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証ステータス</label>
              </div>
              <div class="c-inputWrap__items">
                <span class="c-badge-black">{{ status() }}</span>
              </div>            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>優良設定</label>
              </div>
              <div class="c-inputWrap__items">
                <span class="c-excellent" v-if="disposalLicence.licenceInfo.isExcellent"/>
                <span v-else>-</span>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>自治体</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposalLicence.licenceInfo.municipalityInfo.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証PDF</label>
              </div>
              <div class="c-inputWrap__items">
                <a class="c-text text-link c-entrustText__text" @click="downloadFile(disposalLicence.licenceInfo.licenceNo, false)">
                  {{ disposalLicence.licenceInfo.licenceNo }}.pdf
                </a>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>対象廃棄物種類</label>
              </div>
              <div class="c-inputWrap__items">
                {{ disposalLicence.licenceInfo.targetJwnetItemList.map(t => t.name).join("、") }}
              </div>
            </div>
            <template v-for="(disposalWay, disposalWayIndex) in disposalLicence.licenceInfo.disposalWayList">
              <div class="c-inputWrap" :key="'way-type-' + disposalWayIndex">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  <span>{{ disposalWay.disposalWayType.name }}<br></span>
                  <span>{{ disposalWay.disposalDetailWay }}<br></span>
                </div>
              </div>
            </template>
            <template v-if="!disposalLicence.licenceInfo.disposalWayList.length">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <FloatButtons>
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn secondary small" style="visibility: hidden"></div>
        </div>
        <div class="right">
          <div class="c-btn secondary small next" @click="gotoRelatedEntrust(disposalLicence.licenceInfo.licenceNo)">許可証にひもづく委託契約書</div>
        </div>
      </FloatButtons>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import callApi from "./mixins/callApi";
import {API_URL} from "@/const";
import { saveAs } from "file-saver";
import FloatButtons from "@/components/layout/FloatButtons";
import moment from "moment";

export default {
  name: "licence-disposal-detail",
  components: {FloatButtons},
  mixins: [callApi],
  data() {
    return {};
  },
  methods: {
    status() {
      const licence = this.disposalLicence.licenceInfo;
      const expiredLicence = this.checkExpiredLicence(licence.licenceExpiredDate);
      if (!licence.isUpdate && !expiredLicence) {
        return "使用中";
      } else if (licence.isUpdate) {
        return "更新中";
      } else if (!licence.isUpdate && expiredLicence) {
        return "期限切れ";
      }
    },

    // 許可書終了日の期限
    checkExpiredLicence(licenceExpiredDate) {
      if (!licenceExpiredDate) return true;
      const today = moment();
      const targetDate = moment(licenceExpiredDate);
      const diff = targetDate.diff(today, "days");
      if (diff < 0) {
        return true;
      } else return false;
    },

    //許可証のファイルをダウンロード
    downloadFile(licenceNo, isCommonLicence) {
      const params = new URLSearchParams();
      params.append("isCommonLicence", isCommonLicence)
      axios
        .get(API_URL.DISPOSER.LICENCE_DOWNLOAD + this.$route.params.id + '?' + params.toString(), {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, licenceNo + ".pdf");
        });
    },
    backHandler() {
      this.$router.go(-1);
    },
    gotoRelatedEntrust(licenceNo) {
      this.$router.push(`/entrust?licenceNo=${licenceNo}`);
    },
  },
  mounted() {
    this.getLicenceDetailApi(this.$route.params.id);
  },
};
</script>